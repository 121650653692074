import { formatDate } from '~~/utils/formatDate'
import { secondsToTime } from '~~/utils/secondsToTime'
import { appendQueryParams } from '~/utils/appendQueryParams'

class Card {
    constructor() {
        this.id = null
        this.title = null
        this.timeDuration = null
        this.author = null
        this.startDate = null
        this.img = null
        this.url = null
        this.mediaId = null
        this.privacyStatusId = 3
        this.projectId = null
        this.filmstrip = null
        this.parentGroup = null
        this.progressBarValue = 0
        this.length = 0
        this.isNew = false
        this.isLive = false
    }

    fromQuery(data, aspectRatio, isStatic) {
        if (!data) {
            throw ('No data to map on Card')
        }
        this.id = data.id
        this.length = data.length
        this.title = data.title

        if (data.length) {
            this.timeDuration = secondsToTime(data.length)
        }

        if (data.isLive) {
            this.timeDuration = -1
        }

        this.isLive = data.isLive

        this.author = data.author
        let formattedDate = data.startDate

        if (data.startDate) {

            try {
                formattedDate = formatDate(data.startDate)
            } catch (error) {
                console.error(error)
            }
        }

        this.startDate = formattedDate

        if (data.thumbnail) {

            const queryParams = 'q=80&w=700&format=webp';

            this.img = appendQueryParams(data.thumbnail, queryParams) //+ '?q=80&w=700&format=webp'
        }

        if (data.cardImages && data.cardImages[aspectRatio]) {
            this.img = data.cardImages[aspectRatio] + '?q=80&format=webp'
        }

        if (data.filmstrip) {
            this.filmstrip = data.filmstrip
        }

        if (data.parentGroupId && data.parents?.[data.parentGroupId]) {
            const [group] = data.parents[data.parentGroupId]
            this.parentGroup = { name: group.title, url: group.url }
        }

        if (!isStatic && typeof isStatic != 'undefined' && data.gif) {
            this.img = data.gif
        }

        this.url = data.url || '/'

        this.mediaId = data.mediaId

        this.privacyStatusId = data.privacyStatusId

        this.projectId = data.projectId

        if (data.watchTime && data.length) {
            this.progressBarValue = Math.min(100, Math.ceil((data.watchTime / data.length) * 100))
        }

        if (data.startDate) {
            const difference = Date.now() - new Date(data.startDate)
            const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000
            if (difference < twentyFourHoursInMilliseconds) this.isNew = true
        }
    }
}

export default Card